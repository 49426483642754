import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SnowIcon from './SnowIcon';
import AvalancheIcon from '../AvalancheIcon';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import GOMRating from './GOMRating';
import { snowIconLabels, avalancheIconLabels, getGradeOfManageability, truncateString } from '../utils';

import './snowreport.css';

function getSnowIcon(snowIconSet, snowEntry) {
   if (snowIconSet.has(snowEntry.snow_type)) {
      return (<></>);
   }
   snowIconSet.add(snowEntry.snow_type);
   return (
      <>
         <Tooltip title={snowIconLabels[snowEntry.snow_type]}>
            <Button color="black">
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                  <SnowIcon snowType={snowEntry.snow_type} size={35} />
               </div>
            </Button>
         </Tooltip>
      </>
   );
}


function getAvalancheIcon(avaIconSet, avaEntry) {
   if (avaIconSet.has(avaEntry.problem)) {
      return (<></>);
   }
   avaIconSet.add(avaEntry.problem);
   return (
      <>
         <Tooltip title={avalancheIconLabels[avaEntry.problem]}>
            <Button color="black">
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                  <AvalancheIcon avalancheType={avaEntry.problem} style={{ height: "35px" }} />
               </div>
            </Button>
         </Tooltip>
      </>
   );
}

export default function ReportInfoBox({ report, showInfoBox }) {
   if (!report || report["hidden"]) {
      return <></>;
   }

   const snowIconSet = new Set();
   const avaIconSet = new Set();

   return (
      <div className="info-container">
         <IconButton style={{ "backgroundColor": "white", "left": "90%", "top": "inherit", "border": "2px solid" }} onClick={() => showInfoBox(null)}>
            <CloseIcon />
         </IconButton>
         <Card sx={{ minWidth: 250, maxWidth: 250 }}>
            <CardContent>
               <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                  {report.date}
               </Typography>
               <Typography variant="h5" component="div">
                  {report.regionName}
               </Typography>
               <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>Region</Typography>
               <Divider sx={{ opacity: 1, marginTop: "5px", marginBottom: "5px" }} />
               <Typography align={"center"} sx={{ fontSize: 16 }} >
                  <FaAngleDoubleRight style={{ marginTop: "-4px", marginRight: "3px" }} size={16} />
                  {truncateString(report.title,35)}
                  <FaAngleDoubleLeft style={{ marginTop: "-4px", marginLeft: "3px" }} size={16} />
               </Typography>
               {!!getGradeOfManageability(report.grade_of_manageability) &&
                  <Typography align={"center"}   sx={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: 12 }}>
                     <Divider sx={{ opacity: 0, marginTop: "1px", marginBottom: "2px" }} />
                     <GOMRating iconFontSize={"1.5rem"} fontSize={"12px"} value={getGradeOfManageability(report.grade_of_manageability)} />
                  </Typography>
               }
               {report.snow && report.snow.length > 0 ? (
                  <>
                     <Divider sx={{ opacity: 1, marginTop: "5px" }} />
                     <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 1 }}>
                        {report.snow.map((snowEntry, index) => (
                           getSnowIcon(snowIconSet, snowEntry)
                        ))}
                     </Box>
                     <Typography sx={{ color: 'text.secondary', mb: 1.5, fontSize: 12 }}>Schneebedingungen</Typography>
                  </>
               ) : (<></>)
               }
               {report.avalanche && report.avalanche.length > 0 ? (
                  <>
                     <Divider sx={{ opacity: 1, marginTop: "5px" }} />
                     <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 1 }}>
                        {report.avalanche.map((avaEntry, index) => (
                           getAvalancheIcon(avaIconSet, avaEntry)
                        ))}
                     </Box>
                     <Typography sx={{ color: 'text.secondary', mb: 1.5, fontSize: 12 }}>Lawinenprobleme</Typography>
                  </>
               ) : (<></>)
               }
            </CardContent>
            {report.anchor ? (
               <CardActions style={{ backgroundColor: "#ff5159" }} className="d-flex justify-content-center">
                  <Button
                     style={{ color: "black", fontFamily: "Ubuntu", fontSize: 12 }}
                     size="small"
                     onClick={() => {
                        const element = document.getElementById(report.anchor);
                        if (element) {
                           element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                     }}>
                     Zum Schneereport
                  </Button>
               </CardActions>
            ) : <></>}
         </Card>
      </div>
   );
}