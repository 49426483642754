export const regionNames = require('../eaws/micro-regions_names/de.json');

export function selectRatingColor(rating) {
   switch (rating) {
      case 1: return { fillColor: "#d6fc78" };
      case 2: return { fillColor: "#fffe4a" };
      case 3: return { fillColor: "#f49f30" };
      case 4: return { fillColor: "#ed3a1c" };
      case 5: return { fillColor: "#3e3e3e" };
      default: return {};
   }
}

export function getStyleForAvalancheRegion(rating, baseStyle) {
   const style = { ...baseStyle };
   const ratingStyle = selectRatingColor(rating);
   const resStyle = { ...style, ...ratingStyle };
   return resStyle;
}

export function getRegRatingForProperty(ratings, id, elev) {
   if (!ratings || !id || !elev) {
      return null;
   }
   const elevation = elev == "low_high" ? "" : ":" + elev;
   const regionId = id + elevation;
   const regRating = ratings ? ratings[regionId] : null;
   return regRating;
}

export function decodeHTMLEntities(text) {
   const parser = new DOMParser();
   const doc = parser.parseFromString(text, "text/html");
   return doc.documentElement.textContent;
}

export function getNameForRegionId(regId) {
   return regionNames[regId] || null;
}

export const bulletinProblemTranslation = {
   new_snow: 'newsnow',
   wind_slab: 'windslab',
   persistent_weak_layers: 'weaklayer',
   wet_snow: 'wetsnow',
   gliding_snow: 'glidesnow',
};

export function fixBulletinID(bulletinID) {
   // Remove leading/trailing spaces, replace hyphens and special characters with underscores
   bulletinID = bulletinID.trim().replace(/[^a-zA-Z0-9]/g, "_");
   // Ensure the ID doesn't start with a number (prepend a letter if necessary)
   if (/^\d/.test(bulletinID)) {
      bulletinID = "id_" + bulletinID;
   }
   return bulletinID;
}

export function formatName(ort) {
   if(!ort) {
      return "";
   }
   return ort
      .toLowerCase()
      .split(' ')
      .map(wort => wort.charAt(0).toUpperCase() + wort.slice(1))
      .join(' ');
}
