import React from 'react';
import { useMap } from 'react-leaflet';
import { pbfUrl } from '../utils';
import { regionStyling } from './regionStyles';
import { getStyleForAvalancheRegion, getRegRatingForProperty } from './utils'
import { isCurrentFeature } from '../utils'
import * as L from 'leaflet';

L.DomEvent.fakeStop = function () {
   return true;
}

function VectorTileLayer(props) {
   const map = useMap();
   const data = props.data ? props.data : null;
   const amPm = props.amPmSelectorValue;

   React.useEffect(() => {
      let featureSet = new Set();
      let elevatedFeatureSet = new Set();
      let thresholdMap = new Map();
      const vectorTileOptions = {
         pane: "markerPane",
         rendererFactory: L.svg.tile,
         maxZoom: 10,
         interactive: true,
         vectorTileLayerStyles: {
            'micro-regions': function (properties, zoom) {
               if(!isCurrentFeature(properties)) {
                  return regionStyling.hidden;
               }
               featureSet.add(properties.id);
               return regionStyling.clickable;
            },
            'micro-regions_elevation': function (properties, zoom) {
               if(!isCurrentFeature(properties)) {
                  return regionStyling.hidden;
               }
               const regRating = data.getMaxDangerRatingsForFeature(properties);
               if (regRating) {
                  elevatedFeatureSet.add(properties.id);
                  return getStyleForAvalancheRegion(regRating[amPm+"_"+properties.elevation], regionStyling.mouseOut)
               }
               return regionStyling.hidden;
            },
            'outline': function (properties, zoom) {
               return regionStyling.hidden;
            },
         },
         getFeatureId: function (feature) {
            const outdatedPrefix = isCurrentFeature(feature.properties)?"":"outdated_";
            if (feature && feature.properties.elevation !== undefined) {
               const rid = outdatedPrefix + feature.properties.id + "_" + feature.properties.elevation;
               if(outdatedPrefix==="") {
                  thresholdMap.set(feature.properties.id,feature.properties["elevation line_visualization"]);
               }               
               return rid;
            }
            const rid = outdatedPrefix + feature.properties.id;
            return rid;
         },
      };

      const vectorGrid = L.vectorGrid.protobuf(
         pbfUrl,
         vectorTileOptions
      ).addTo(map);

      // Event-Handler hinzufügen
      props.vectorGridInitializer(vectorGrid, featureSet, elevatedFeatureSet, props.showInfoBox);

      return () => {
         map.removeLayer(vectorGrid);
      };

   }, [map, data, amPm]);

   return null;
}

export default VectorTileLayer;