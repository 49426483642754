import React, { useState, useEffect } from "react";
import { EChart } from '@kbox-labs/react-echarts'
import { arrayIsSet } from '../utils';

function findMatchingPairs(array1, array2) {
   const matchingPairs = [];
   array1.forEach(obj1 => {
      const key1 = obj1[0];
      let notfound = true;
      for (let i = 0; (i<array2.length)&&notfound; i++) {
         if(key1==array2[i][0]) {
            matchingPairs.push({ key: key1, vw: obj1[1], dw: array2[i][1] });
            notfound = false;
         }
      }
   });

   return matchingPairs;
}

export const StationComponentWindRoseChart = (props) => {
   const divRef = props.divRef;
   const [width, setWidth] = useState(0);
   const [height, setHeight] = useState(0);

   useEffect(() => {
      const handleResize = (entries) => {
         for (let entry of entries) {
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
         }
      };

      const observer = new ResizeObserver(handleResize);
      const observed = divRef.current;

      if (divRef.current) {
         observer.observe(divRef.current);
      }

      return () => {
         if (observed) {
            observer.unobserve(observed);
         }
      };
   }, [divRef]);

   if (!props || !props.series) {
      // no data given
      return <></>;
   }

   const dwData = props.series['dw'];
   const vwData = props.series['vw'];

   if (!arrayIsSet(dwData) || !arrayIsSet(vwData)) {
      return <></>;
   }

   const resArr = findMatchingPairs(vwData, dwData);

   const legendName = [
      "0-10 km/h",
      "10-20 km/h",
      "20-40 km/h",
      "40-60 km/h",
      "60-100 km/h",
      "100-140 km/h",
      ">140 km/h"
   ];

   const l0Data = new Array(8).fill(0);
   const l1Data = new Array(8).fill(0);
   const l2Data = new Array(8).fill(0);
   const l3Data = new Array(8).fill(0);
   const l4Data = new Array(8).fill(0);
   const l5Data = new Array(8).fill(0);
   const l6Data = new Array(8).fill(0);

   
   resArr.forEach(obj1 => {
      const arrIdx = Math.floor(((parseInt(obj1.dw)+22.5)%360)/ 45);
      const vwVal = obj1.vw;
      if(vwVal<=10) { l0Data[arrIdx] += 1; }
      else if(vwVal<=20) { l1Data[arrIdx] += 1; }
      else if(vwVal<=40) { l2Data[arrIdx] += 1; }
      else if(vwVal<=60) { l3Data[arrIdx] += 1; }
      else if(vwVal<=100) { l4Data[arrIdx] += 1; }
      else if(vwVal<=140) { l5Data[arrIdx] += 1; }
      else { l6Data[arrIdx] += 1; }
   });

   let maxVal = 0;
   for(let i=0;i<8;++i) {
      maxVal = Math.max(l0Data[i],l1Data[i],l2Data[i],l3Data[i],l4Data[i],l5Data[i],l6Data[i],maxVal);
   }


   return (
      <>
         {props.title}<p />
         {(!!width) && (
            <EChart
               key={width}
               renderer={'svg'}
               width={width - 15}
               height={height - 50}
               animation={false}
               color={["#0001F7", "#00B8FE", "#00FFFF", "#00FF68", "#BEFE00", "#FFFF00", "#FFA800", "#E10100"]}
               angleAxis={{
                  type: 'category',
                  data: ["N", "NE", "E", "SE", "S", "SW", "W", "NW","N"],
                  boundaryGap: false,
                  startAngle: 90,
                  endAngle: -310,
                  axisTick: {
                     show: false
                  },
                  splitLine: {
                     show: true,
                     lineStyle: {
                        // color:"black"
                     },
                  },
                  axisLabel: {
                     show: true,
                     interval: 0,
                  },
               }}
               radiusAxis={{
                  min: 0,
                  //max: maxVal,
                  axisLabel: {
                     show: false
                  },
                  axisTick: {
                     show: false
                  },
                  axisLine: {
                     show: false
                  },
               }}
               polar={{ 
                  center: ["35%", "50%"],
                  radius: "60%"
               }}
               series={
                  [{
                     type: 'bar',
                     data: l0Data,
                     coordinateSystem: 'polar',
                     name: legendName[0],
                     stack: 'a'
                  }, {
                     type: 'bar',
                     data: l1Data,
                     coordinateSystem: 'polar',
                     name: legendName[1],
                     stack: 'a'
                  }, {
                     type: 'bar',
                     data: l2Data,
                     coordinateSystem: 'polar',
                     name: legendName[2],
                     stack: 'a'
                  }, {
                     type: 'bar',
                     data: l3Data,
                     coordinateSystem: 'polar',
                     name: legendName[3],
                     stack: 'a'
                  }, {
                     type: 'bar',
                     data: l4Data,
                     coordinateSystem: 'polar',
                     name: legendName[4],
                     stack: 'a'
                  }, {
                     type: 'bar',
                     data: l5Data,
                     coordinateSystem: 'polar',
                     name: legendName[5],
                     stack: 'a'
                  }, {
                     type: 'bar',
                     data: l6Data,
                     coordinateSystem: 'polar',
                     name: legendName[6],
                     stack: 'a'
                  }]}
               legend={{
                  show: true,
                  orient: "vertical",
                  right: 0,
                  data: legendName,
                  width: 500,
               }}

            />
         )}
      </>
   )
}