const CustomIcon = ({ src, size = 40, width, height, alt = "Icon", ...props }) => {
   return (
     <img
       src={src}
       alt={alt}
       style={{
         width: width || size, // Fallback auf `size`, wenn `width` nicht angegeben ist
         height: height || size, // Fallback auf `size`, wenn `height` nicht angegeben ist
         ...props.style, // zusätzliche Styles
       }}
       {...props} // Weitergabe anderer Attribute, z.B. `onClick`
     />
   );
 };
 
 export default CustomIcon;