import React from 'react';
import Grid from '@mui/material/Grid2';

import './avalanchereport.css';
import ReportPaper from './ReportPaper';


function Report(props) {
   const bulletin = props.selectedBulletin;

   return (
      <>
         <div style={{ maxWidth: "1100px", marginLeft: "auto", marginRight: "auto" }} >
            <Grid container>
               <Grid size={{ xs: 12 }} className="d-flex justify-content-center" >
                  { bulletin && <ReportPaper bulletin={bulletin} /> }
               </Grid>
            </Grid>
         </div>
      </>
   );
}

export default Report;
