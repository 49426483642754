const CACHE_SIZE_LIMIT = 6;
const CACHE_TIME_LIMIT = 15 * 60 * 1000;

let stationsCache = {};
let stationsFetchTime = {};

export const getStations = async (type) => {
  const currentTime = Date.now();
  
  // Überprüfen, ob der Typ bereits im Cache vorhanden ist und die Daten aktuell sind
  if (stationsCache[type] && currentTime - stationsFetchTime[type] < CACHE_TIME_LIMIT) {
    return stationsCache[type];
  }

  try {
    const result = await fetch(`https://alpinemeteo.com/data/${type}.json`);
    const data = await result.json();

    // Cache für den entsprechenden Typ aktualisieren
    stationsCache[type] = data;
    stationsFetchTime[type] = currentTime;

    return data;
  } catch (error) {
    console.error('Error querying data: ', error);
    return { "error": error.toString() };
  }
};

export const getAllStations = async () => {
  return getStations("station");
};

let latestStationDataCache = new Map();

export const getLatestDataForStation = async (id) => {
  const currentTime = Date.now();

  // Prüfen, ob die Daten für die Station im Cache sind
  if (latestStationDataCache.has(id)) {
    const { data, timestamp } = latestStationDataCache.get(id);
    // Prüfen, ob die Daten noch jünger als 15 Minuten sind
    if (currentTime - timestamp < CACHE_TIME_LIMIT) {
      return data;
    }
  }

  // Daten sind entweder nicht im Cache oder älter als 15 Minuten, daher neu abrufen
  try {
    const result = await fetch(`https://alpinemeteo.com/alpinemeteoapi/v1/station/${id}/latest`);
    const data = await result.json();

    // Daten in den Cache hinzufügen
    latestStationDataCache.set(id, { data, timestamp: currentTime });

    // Wenn der Cache mehr als 5 Einträge enthält, den ältesten Eintrag entfernen
    if (latestStationDataCache.size > CACHE_SIZE_LIMIT) {
      // Den ersten Eintrag (den ältesten) entfernen
      const oldestKey = latestStationDataCache.keys().next().value;
      latestStationDataCache.delete(oldestKey);
    }

    return data;
  } catch (error) {
    console.error('Error querying data: ', error);
    return { "error": toString(error) };
  }
};

let stationDataCache = new Map();

export const getDataForStation = async (id, from, to) => {
  const currentTime = Date.now();
  const cacheKey = `${id}-${from}-${to}`; 

  // Prüfen, ob die Daten im Cache sind
  if (stationDataCache.has(cacheKey)) {
    const { data, timestamp } = stationDataCache.get(cacheKey);

    // Prüfen, ob die Daten jünger als 15 Minuten sind
    if (currentTime - timestamp < CACHE_TIME_LIMIT) {
      return data;
    }
  }

  // Daten sind entweder nicht im Cache oder älter als 15 Minuten
  try {
    const result = await fetch(`https://alpinemeteo.com/alpinemeteoapi/v1/station/${id}/${from}/${to}`);
    const data = await result.json();

    // Cache aktualisieren
    stationDataCache.set(cacheKey, { data, timestamp: currentTime });

    // Wenn der Cache mehr als 5 Einträge enthält, den ältesten entfernen
    if (stationDataCache.size > CACHE_SIZE_LIMIT) {
      const oldestKey = stationDataCache.keys().next().value;
      stationDataCache.delete(oldestKey);
    }

    return data;
  } catch (error) {
    console.error('Error querying data: ', error);
    return { "error": toString(error) };
  }
  
};

let latestStationMetadataCache = new Map();

export const getStationMetadata = async (id) => {
  const currentTime = Date.now();

  // Prüfen, ob die Daten für die Station im Cache sind
  if (latestStationMetadataCache.has(id)) {
    const { data, timestamp } = latestStationMetadataCache.get(id);
    // Prüfen, ob die Daten noch jünger als 15 Minuten sind
    if (currentTime - timestamp < CACHE_TIME_LIMIT) {
      return data;
    }
  }

  try {
    const result = await fetch('https://alpinemeteo.com/alpinemeteoapi/v1/station/' + id);
    const data = await result.json();

    // Daten in den Cache hinzufügen
    latestStationMetadataCache.set(id, { data, timestamp: currentTime });

    // Wenn der Cache mehr als 5 Einträge enthält, den ältesten Eintrag entfernen
    if (latestStationMetadataCache.size > CACHE_SIZE_LIMIT) {
      // Den ersten Eintrag (den ältesten) entfernen
      const oldestKey = latestStationMetadataCache.keys().next().value;
      latestStationMetadataCache.delete(oldestKey);
    }

    return data;
  } catch (error) {
    return { "error": toString(error) };
  }
}
