import React, { useState, useEffect } from "react";
import { EChart } from '@kbox-labs/react-echarts'
import { getParamName, getParamUnit } from '../utils';
import { useTranslation } from "react-i18next";

export const StationComponentWindDirectionChart = (props) => {
   const divRef = props.divRef;
   const [width, setWidth] = useState(0);
   const [height, setHeight] = useState(0);

   const { t } = useTranslation();

   useEffect(() => {
      const handleResize = (entries) => {
         for (let entry of entries) {
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
         }
      };

      const observer = new ResizeObserver(handleResize);
      const observed = divRef.current;

      if (divRef.current) {
         observer.observe(divRef.current);
      }

      return () => {
         if (observed) {
            observer.unobserve(observed);
         }
      };
   }, [divRef]);

   if (!props||!props.series) {
      // no data given
      return <></>;
   }

   const seriesArr = [];
   const seriesColor = [];
   const seriesLegend = [];

   Object.entries(props.series).forEach(([key, values]) => {
      if (!values || values == [] || !values.length) {
         return;
      }
      const keyHasStationName = key.indexOf(' ');
      let paramName = "";
      if(keyHasStationName===-1) {
         // no station name. just the parameter name
         paramName = getParamName(key,t)+" ["+getParamUnit(key)+"]";
      } else {
         // get the parameter name and add the station name afterwards
         const cleanK = key.substring(0,keyHasStationName);
         const stationName = key.substring(keyHasStationName+1);
         paramName = cleanK+" "+stationName+" ["+getParamUnit(cleanK)+"]";
      }
      seriesArr.push({
         type: props.seriesType[key],
         lineStyle: { opacity: 0 },
         showSymbol: true,
         data: values,
         name: paramName,
         markLine: {
            data: [
               // 1st line we want to draw
               [
                  // start point of the line
                  // we have to defined line attributes only here (not in the end point)
                  {
                     xAxis: values[0][0],
                     yAxis: 90,
                     symbol: 'none',
                     lineStyle: {
                        normal: {
                           color: "#a11",
                           width: 1,
                        },
                     },
                     label: {
                        normal: {
                           show: true,
                           position: 'end',
                           formatter: 'E'
                        }
                     }
                  },
                  // end point of the line
                  {
                     xAxis: values[values.length - 1][0],
                     yAxis: 90,
                     symbol: 'none'
                  }
               ],
               // 2nd line we want to draw
               [
                  // start point of the line
                  // we have to defined line attributes only here (not in the end point)
                  {
                     xAxis: values[0][0],
                     yAxis: 180,
                     symbol: 'none',
                     lineStyle: {
                        normal: {
                           color: "#f71",
                           width: 1,
                        },
                     },
                     label: {
                        normal: {
                           show: true,
                           position: 'end',
                           formatter: 'S'
                        }
                     }
                  },
                  // end point of the line
                  {
                     xAxis: values[values.length - 1][0],
                     yAxis: 180,
                     symbol: 'none'
                  }
               ],
               // 3rd line we want to draw
               [
                  // start point of the line
                  // we have to defined line attributes only here (not in the end point)
                  {
                     xAxis: values[0][0],
                     yAxis: 270,
                     symbol: 'none',
                     lineStyle: {
                        normal: {
                           color: "#ad1",
                           width: 1,
                        },
                     },
                     label: {
                        normal: {
                           show: true,
                           position: 'end',
                           formatter: 'W'
                        }
                     }
                  },
                  // end point of the line
                  {
                     xAxis: values[values.length - 1][0],
                     yAxis: 270,
                     symbol: 'none'
                  }
               ],
               // 4th line we want to draw
               [
                  // start point of the line
                  // we have to defined line attributes only here (not in the end point)
                  {
                     xAxis: values[0][0],
                     yAxis: 360,
                     symbol: 'none',
                     lineStyle: {
                        normal: {
                           color: "#55e",
                           width: 1,
                        },
                     },
                     label: {
                        normal: {
                           show: true,
                           position: 'end',
                           formatter: 'N'
                        }
                     }
                  },
                  // end point of the line
                  {
                     xAxis: values[values.length - 1][0],
                     yAxis: 360,
                     symbol: 'none'
                  }
               ],
               // 5th line we want to draw
               [
                  // start point of the line
                  // we have to defined line attributes only here (not in the end point)
                  {
                     xAxis: values[values.length - 2][0],
                     yAxis: 0,
                     symbol: 'none',
                     lineStyle: {
                        normal: {
                           color: "#55e",
                           width: 1,
                        },
                     },
                     label: {
                        normal: {
                           show: true,
                           position: 'end',
                           formatter: 'N'
                        }
                     }
                  },
                  // end point of the line
                  {
                     xAxis: values[values.length - 1][0],
                     yAxis: 0,
                     symbol: 'none'
                  }
               ]
            ]
         }
      });
      seriesColor.push(props.seriesColor[key]);
      seriesLegend.push(paramName);
   });

   if (!seriesArr.length) {
      return <></>;
   }

   return (
      <>
         {props.title}<p />
         {(!!width) && (
            <EChart
               key={width}
               renderer={'svg'}
               width={width - 15}
               height={height}
               animation={false}
               xAxis={{
                  type: 'time',
                  show: true,
                  boundaryGap: false,
                  splitLine: { show: true }
               }}
               tooltip={{ trigger: 'axis' }}
               grid={{
                  show: true,
                  top: "35px",
                  left: "50px",
                  right: "40px",
                  bottom: props.bottom?props.bottom:"120px"
               }}
               legend={{ 
                  data: seriesLegend,
                  bottom : 70 
               }}
               yAxis={{
                  name: props.axisName,
                  type: 'value',
                  interval: 90,
                  min: 0,
                  max: 360,
                  boundaryGap: [0, '30%'],
                  axisTick: { show: true },
                  minorTick: { show: false },
                  minorSplitLine: { show: false },
                  data: [0, 90, 180, 270]

               }}
               series={seriesArr}
               color={seriesColor}
               //toolbox={{
               //   show: true,
               //   top: "-10px",
               //   right: "10px",
               //   feature: { saveAsImage: {} }
               //}}
            />)}
      </>
   );
}