import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

const LocateControl = () => {
   const map = useMap();

   useEffect(() => {
      const L = require('leaflet');
      require('leaflet.locatecontrol');

      const locateControl = L.control.locate({
         position: 'topleft', // Position der Locate-Schaltfläche
         setView: 'once', // Nur einmal auf die Position des Benutzers zentrieren
         flyTo: true, // Animierte Bewegung zum Standort
         keepCurrentZoomLevel: true, // Beibehaltung des aktuellen Zoom-Levels
      });

      locateControl.addTo(map);

      return () => {
         locateControl.remove(); // Clean-up, wenn die Komponente unmountet
      };
   }, [map]);

   return null;
};

export default LocateControl;