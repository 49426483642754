import React from 'react';
import { PiSnowflake } from 'react-icons/pi';
import { IoIosSunny } from 'react-icons/io';
import { FaUmbrellaBeach } from "react-icons/fa6";
import { BsLayersHalf } from 'react-icons/bs';
import { GiWindsock } from 'react-icons/gi';
import BruchharschIcon from './BruchharschIcon';
import RottensnowIcon from './RottensnowIcon';
import IceIcon from './IceIcon';
import { GiCrystalBars } from "react-icons/gi";

export const snowIcons = {
  pulverschnee: PiSnowflake,
  firn: IoIosSunny,
  bruchharsch: BruchharschIcon,
  faulschnee: RottensnowIcon,
  vereist: IceIcon,
  harschdeckel: BsLayersHalf,
  sommerschnee: FaUmbrellaBeach,
  noppenpulver: GiCrystalBars,
  windbeeinflusst: GiWindsock,
};

class SnowIcon extends React.Component {
  getSnowIcon(snowType) {
    const IconComponent = snowIcons[snowType];
    return IconComponent ? <IconComponent size={this.props.size} style={this.props.style} /> : <></>;
  }

  render() {
    const { snowType } = this.props;
    return this.getSnowIcon(snowType);
  }
}

export default SnowIcon;