import React, { useState } from "react";
import { DateRange } from "react-date-range";
import { Container, Row, Col } from 'react-bootstrap';
import { CiCalendarDate } from "react-icons/ci";
import dayjs from 'dayjs';


import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./alpinemeteo.css";


const AlpinemeteoDateInfoBar = (props) => {
   const [isDatePickerVisible, setDatePickerVisible] = useState(false);
   const toggleDatePicker = () => {
      setDatePickerVisible(!isDatePickerVisible);
   };

   const [selectedDateRange, setSelectedDateRange] = useState({
      startDate: dayjs(new Date()).subtract(6, 'day').toDate(),
      endDate: new Date(),
      key: "selection"
   });

   const handleSelect = ranges => {
      const updatedDict = {
         ...ranges.selection,
         cnt: (selectedDateRange.cnt || 0) + 1
      };
      setSelectedDateRange(updatedDict);
      if (updatedDict.cnt % 2 == 0) {
         setTimeout(() => {
            toggleDatePicker();
            props.setNewDateRange([updatedDict.startDate, updatedDict.endDate]);
         }, 100);
      }
   };

   const localeFormat = dayjs.localeData().longDateFormat('L');

   return (
      <>
         <Container fluid>
            <div className="date-bar">
               <Row>
                  {/* Linke Spalte (Datum) */}
                  <Col xs={12}>
                     <div className="date-bar-left">
                        {selectedDateRange ? dayjs(selectedDateRange.startDate).format(localeFormat) + " - " + dayjs(selectedDateRange.endDate).format(localeFormat) : ""}
                        <br style={{ pointerEvents: "none", userSelect: "none" }}></br>
                        <CiCalendarDate className="picker-icon" onClick={toggleDatePicker} />
                     </div>
                  </Col>
               </Row>
            <Row>
               <Col>
                  <div id={"date-picker-div"} style={{ "display": isDatePickerVisible ? "block" : "none", "position": "absolute", "left": "10px", "backgroundColor": "white", "zIndex": "1050", "border": "1px solid", "marginTop": "5px" }}>
                     <React.Fragment>
                        <DateRange
                           onChange={handleSelect}
                           showSelectionPreview={true}
                           editableDateInputs={false}
                           showMonthAndYearPickers={true}
                           showDateDisplay={false}
                           maxDate={new Date()}
                           ranges={[selectedDateRange]}
                           rangeColors={["#ff5159"]}
                           color={"#ff5159"}
                        />
                     </React.Fragment>

                  </div>
               </Col>
            </Row>
            </div>
         </Container>

      </>
   );
};

export default AlpinemeteoDateInfoBar;
