import { React, useState } from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import ReactMarkdown from 'react-markdown'

import AvalancheConditionEntry from './AvalancheConditionEntry';
import { formatTimestampToUserLocale } from '../utils';
import { bulletinProblemTranslation, fixBulletinID } from './utils';
import { regionNames, formatName } from "./utils";
import { CgDanger } from "react-icons/cg";

import './avalanchereport.css';

const ReportPaperElem = styled(Paper)(({ theme }) => ({
   width: "90%",
   margin: "20px",
   padding: theme.spacing(2),
   ...theme.typography.body2,
   textAlign: 'center',
}));

function ReportPaper({ bulletin }) {

   const problems = bulletin && bulletin["avalancheProblems"] ? bulletin["avalancheProblems"] : [];
   const parsedProblems = {};
   problems.forEach((problem) => {
      const pt = problem.problemType;
      if (pt == "favourable_situation" || pt == "no_distinct_avalanche_problem" || pt == "cornices") {
         return;
      }
      const key = bulletinProblemTranslation[pt];
      const aspects = problem.aspects && problem.aspects.length ? problem.aspects : ["all"];
      const elevation = problem.elevation ? [problem.elevation.lowerBound, problem.elevation.upperBound] : null;
      const validTime = problem.validTimePeriod ? problem.validTimePeriod : "all_day";
      const newAspects = [];
      for (const pid in aspects) {
         newAspects.push({ expo: aspects[pid] });
      }

      if (!parsedProblems[key]) {
         parsedProblems[key] = [];
      }

      // Check if elevation is not null before accessing
      const elevationRange = elevation ? [
         elevation[0] ? elevation[0] : null, elevation[1] ? elevation[1] : null
      ] : [null, null];
      let validTimeEntry = null;
      if (validTime == "earlier") validTimeEntry = "Vormittag";
      if (validTime == "later") validTimeEntry = "Nachmittag";
      const newEntry = [elevationRange[0], elevationRange[1], newAspects, validTimeEntry];
      const newEntryString = JSON.stringify(newEntry);
      const exists = parsedProblems[key].some(entry => JSON.stringify(entry) === newEntryString);
      if (!exists) {
         parsedProblems[key].push(newEntry);
      }
   });

   return (
      <>
         <div id={"avalancheReportDetails"} style={{ scrollMarginTop: "50px" }}></div>
         <ReportPaperElem elevation={6}>
            <div className="report-box">
               <Grid container >
                  <Grid container size={{ xs: 12, md: 12 }} className="d-flex align-items-center justify-content-center">
                     <Grid size={{ xs: 12 }} className="d-flex align-items-start justify-content-center" >
                        <h3 className="report-heading">Lawinenlagebericht gültig von {formatTimestampToUserLocale(bulletin.validTime.startTime, true)} bis {formatTimestampToUserLocale(bulletin.validTime.endTime, true)}</h3>
                     </Grid>
                     <Grid container item size={{ xs: 12 }} className="d-flex justify-content-center">
                        {bulletin.regions.map((region, idx) => (
                           <Grid item key={idx}>
                              <Chip style={{ margin: "10px" }} variant="outlined" label={formatName(regionNames[region.regionID])} />
                           </Grid>
                        ))}
                     </Grid>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} className="d-flex justify-content-center">

                  </Grid>

                  <Grid size={{ xs: 12 }} >
                     <hr style={{ border: '1px solid black', opacity: '.5' }} />
                  </Grid>
                  <Grid size={{ xs: 12, md: 12 }} className="d-flex align-items-center justify-content-center">
                     <div style={{ width: "100%" }}>
                        <Grid container >
                           {Object.keys(parsedProblems).length > 0 &&
                              <>
                                 <Grid size={{ xs: 1, md: 1 }} className="d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-center avalanche-grid">
                                       <Chip
                                          sx={{
                                             transform: 'rotate(-90deg)',
                                             transformOrigin: 'center',
                                             minWidth: '100px',
                                             backgroundColor: "#ebd3f2",
                                          }}
                                          icon={<CgDanger size={"20px"} />}
                                          label="Lawinen"
                                          size="string"
                                       />
                                    </div>
                                 </Grid>
                                 <Grid size={{ xs: 11, sm: 11 }} >
                                    {Object.entries(parsedProblems).map(([avalancheCondition, subColl], index) => (
                                       <AvalancheConditionEntry
                                          isFirstEntry={index === 0}
                                          avalancheType={avalancheCondition}
                                          avalancheConditionData={subColl}
                                          cssIdentifier={fixBulletinID(bulletin.bulletinID) + "-" + index}
                                       />
                                    ))}
                                 </Grid>
                                 <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '10px' }}>
                                    <hr style={{ opacity: '.5', border: '1px solid black', margin: '0 0 10px 0' }} />
                                 </Grid>
                                 {bulletin.avalancheProblems?.length > 0 &&
                                    Array.from(new Set(bulletin.avalancheProblems.map(problem => problem.comment)))
                                       .map((comment, index) => (
                                          <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '10px' }}>
                                             <ReactMarkdown
                                                key={index}
                                                skipHtml={true}
                                                children={comment}
                                             />
                                          </Grid>
                                       ))}
                                 {!!(bulletin.avalancheActivity && bulletin.avalancheActivity.comment) &&
                                    <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '10px' }}>
                                       <ReactMarkdown skipHtml={true} children={bulletin.avalancheActivity.comment} />
                                    </Grid>
                                 }
                                 <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '10px' }}>
                                    <Button
                                       style={{ color: "black", fontFamily: "Ubuntu", fontSize: 12 }}
                                       variant="outlined"
                                       color="#ff0000"
                                       onClick={() => {
                                          window.open(bulletin.source.provider.website, "_blank");
                                       }}>
                                       Mehr Infos
                                    </Button>
                                 </Grid>
                              </>
                           }
                        </Grid>
                     </div>
                  </Grid>
               </Grid>
            </div>
         </ReportPaperElem >
      </>);
}

export default ReportPaper;
