import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DatePicker(props) {
  const now = dayjs().tz('Europe/Berlin'); // CET Zeitzone
  const isAfter5PM = now.hour() >= 17;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        disableFuture={!isAfter5PM} // Dynamische Kontrolle
        maxDate={isAfter5PM ? now.add(1, 'day').startOf('day') : now.startOf('day')}
        value={props.value}
        onChange={(newValue) => {
          props.setDate(newValue);
        }}
        sx={{
          "& .MuiPickersDay-root": {
            "&.Mui-selected": {
              backgroundColor: "#b84d4d",
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
