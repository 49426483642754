import React from 'react';
import { SocialIcon } from 'react-social-icons'

const Footer = () => {
   const currentYear = new Date().getFullYear(); // Holt das aktuelle Jahr
   const startYear = 2024; // Jahr, ab dem dein Copyright gilt

   return (
      <footer style={footerStyle}>
         <div style={containerStyle}>
            <div style={linksStyle}>
               <h4 style={headerStyle}>Links</h4>
               <ul style={listStyle}>
                  <li><a href="/contact" style={linkStyle}>Kontakt</a></li>
                  <li><a href="/privacy" style={linkStyle}>Datenschutz</a></li>
                  <li><a href="/impressum" style={linkStyle}>Impressum</a></li>
               </ul>
            </div>
            <div style={socialMediaStyle}>
               <h4 style={headerStyle}>Folge uns</h4>
               <div>
                  <SocialIcon style={{ "margin": "0 10px 10px 10px" }} url="https://facebook.com" />
                  <SocialIcon style={{ "margin": "0 10px 10px 10px" }} url="https://instagram.com" />
               </div>
            </div>
         </div>
         <div style={bottomStyle}>
            <p>
               © {startYear === currentYear ? currentYear : `${startYear} - ${currentYear}`} schnee.report
            </p>
         </div>
      </footer>
   );
};

const footerStyle = {
   background: '#333',
   color: '#fff',
   padding: '20px 0',
   marginTop: '20px',
   position: 'relative',
   bottom: '0',
   width: '100%',
   fontFamily: 'Ubuntu'
};

const containerStyle = {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'flex-start',
   maxWidth: '1200px',
   margin: '0 auto',
   padding: '0 20px',
};

const linksStyle = {
   flex: 1,
};

const socialMediaStyle = {
   flex: 1,
   textAlign: 'right',
};

const headerStyle = {
   margin: '0 0 10px 0', 
};

const listStyle = {
   listStyle: 'none',
   padding: 0,
};

const linkStyle = {
   color: '#fff',
   textDecoration: 'none',
   marginBottom: '10px',
   display: 'block',
};

const iconStyle = {
   color: '#fff',
   textDecoration: 'none',
   marginLeft: '10px',
};

const bottomStyle = {
   textAlign: 'center',
   marginTop: '10px',
};

export default Footer;