import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap";
import Grid from '@mui/material/Grid2';
import { LuClock9, LuClock3 } from "react-icons/lu";

const AmPmSelector = (props) => {
 
   return (
      <div style={{ maxWidth: "1100px", marginLeft: "auto", marginRight: "auto" }} >
         <Grid container>
            <Grid size={{ xs: 12 }} className="d-flex justify-content-center" >
               <div style={{ margin: '10px' }} >
                  <Container>
                     <Row className="justify-content-center">
                     <span style={{ fontSize: "14px" }}>Tageszeit wählen </span>
                        <Col xs={12}>
                           <Row>
                              <Col xs={6} sm={6} md={6} className="mb-2" style={{ border: (props.amPm == "earlier") ? "1px solid black" : "1px solid white" }}>
                                 <Tooltip title={"Vormittag"}>
                                    <Button
                                       onClick={() => props.setAmPm("earlier")}
                                       color="black"
                                       fullWidth >
                                       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                          <LuClock9 size={40} />
                                          <span style={{ fontSize: "10px" }}>{"Vormittag"}</span>
                                       </div>
                                    </Button>
                                 </Tooltip>
                              </Col>
                              <Col xs={6} sm={6} md={6} className="mb-2" style={{ border: (props.amPm == "later") ? "1px solid black" : "1px solid white" }}>
                                 <Tooltip title={"Nachmittag"}>
                                    <Button
                                       onClick={() => props.setAmPm("later")}
                                       color="black"
                                       fullWidth >
                                       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                          <LuClock3 size={40} />
                                          <span style={{ fontSize: "10px" }}>{"Nachmittag"}</span>
                                       </div>
                                    </Button>
                                 </Tooltip>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </Container>
               </div>
            </Grid>
         </Grid>
      </div>
   );
};

export default AmPmSelector;