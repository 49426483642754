import { React, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';

import { formatTimestampToUserLocale, arrayIsSet } from '../utils';
import { StationComponentMap } from './StationComponentMap';
import { StationComponentLatestData } from './StationComponentLatestData';
import { StationComponentWindRoseChart } from './StationComponentWindroseChart';
import { StationComponentWindDirectionChart } from './StationComponentWindDirectionChart';
import { StationComponentLineChart } from './StationComponentLineChart';
import { StationComponentRadiationChart } from './StationComponentRadiationChart';

import './alpinemeteo.css';

export const StationItem = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: '10px',
   textAlign: 'center',
   color: theme.palette.text.secondary,
   width: '100%',
   height: '100%'
}));

const StationPaperElem = styled(Paper)(({ theme }) => ({
   width: "90%",
   margin: "20px",
   padding: theme.spacing(2),
   ...theme.typography.body2,
   textAlign: 'center',
}));

function StationPaper(props) {

   const snowChartDivRef = useRef(null);
   const tempChartDivRef = useRef(null);
   const windChartDivRef = useRef(null);
   const windRoseChartDivRef = useRef(null);
   const windDirChartDivRef = useRef(null);
   const radChartDivRef = useRef(null);

   const stationMetadata = props.stationMetadata;
   const stationData = props.stationData;

   return (
      <>
         <div style={{ maxWidth: "1300px", marginLeft: "auto", marginRight: "auto" }} className="d-flex align-items-start justify-content-center" >
         <div id={"station-box"} style={{ scrollMarginTop: "25px" }}></div>
            <StationPaperElem elevation={6}>
               <div>
                  <Grid container >
                     <Grid size={{ xs: 12 }} className="d-flex align-items-start justify-content-center" >
                        <h3 className="report-heading">Messdaten für {formatTimestampToUserLocale(props.from)} - {formatTimestampToUserLocale(props.to)}</h3>
                     </Grid>
                     {(stationMetadata["lat"] && stationMetadata["long"]) && (
                        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                           <StationComponentMap lat={stationMetadata["lat"]} lng={stationMetadata["long"]} name={stationMetadata["name"]} />
                        </Grid>
                     )}
                     {(
                        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                           <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh', overflow: 'auto' }}>
                              <StationComponentLatestData vals={stationData} />
                           </StationItem>
                        </Grid>
                     )}
                     {(arrayIsSet(stationData["dw"]) && arrayIsSet(stationData["vw"])) && (
                        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                           <div ref={windRoseChartDivRef} style={{ minHeight: '100%', minWidth: '100%' }}>
                              <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh', overflow: 'none' }}>
                                 <StationComponentWindRoseChart
                                    title={""}
                                    divRef={windRoseChartDivRef}
                                    series={{ 'dw': stationData['dw'], 'vw': stationData['vw'] }}
                                 />
                              </StationItem>
                           </div>
                        </Grid>
                     )}
                     {(arrayIsSet(stationData["dw"])) && (
                        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                           <div ref={windDirChartDivRef} style={{ minHeight: '100%', minWidth: '100%' }}>
                              <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh' }}>
                                 <StationComponentWindDirectionChart
                                    title={""}
                                    divRef={windDirChartDivRef}
                                    series={{ 'dw': stationData['dw'] }}
                                    seriesType={{ 'dw': 'line' }}
                                    seriesColor={{ 'dw': '#8f3bb8' }}
                                    axisName="[°]"
                                 />
                              </StationItem>
                           </div>
                        </Grid>
                     )}
                     {(arrayIsSet(stationData["vw"]) || arrayIsSet(stationData["vwmax"])) && (
                        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                           <div ref={windChartDivRef} style={{ minHeight: '100%', minWidth: '100%' }}>
                              <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh' }}>
                                 <StationComponentLineChart
                                    title={""}
                                    divRef={windChartDivRef}
                                    series={{ 'vw': stationData['vw'], 'vwmax': stationData['vwmax'] }}
                                    seriesType={{ 'vw': 'line', 'vwmax': 'line' }}
                                    seriesColor={{ 'vw': '#7c2ea3', 'vwmax': '#1250b0' }}
                                    axisName="[km/h]"
                                 />
                              </StationItem>
                           </div>
                        </Grid>
                     )}
                     {(arrayIsSet(stationData["hs"]) || arrayIsSet(stationData["hs24h"])) && (
                        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                           <div ref={snowChartDivRef} style={{ minHeight: '100%', minWidth: '100%' }}>
                              <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh' }}>
                                 <StationComponentLineChart
                                    title={""}
                                    divRef={snowChartDivRef}
                                    series={{ 'hs': stationData['hs'], 'hs24h': stationData['hs24h'] }}
                                    seriesType={{ 'hs': 'line', 'hs24h': 'line' }}
                                    seriesColor={{ 'hs': '#56a64b', 'hs24h': '#e0b400' }}
                                    axisName="[cm]"
                                 />
                              </StationItem>
                           </div>
                        </Grid>
                     )}
                     {(arrayIsSet(stationData["ta"]) || arrayIsSet(stationData["td"]) || arrayIsSet(stationData["tss"])) && (
                        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                           <div ref={tempChartDivRef} style={{ minHeight: '100%', minWidth: '100%' }}>
                              <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh' }}>
                                 <StationComponentLineChart
                                    title={""}
                                    divRef={tempChartDivRef}
                                    series={{ 'ta': stationData['ta'], 'td': stationData['td'], 'tss': stationData['tss'] }}
                                    seriesType={{ 'ta': 'line', 'td': 'line', 'tss': 'line' }}
                                    seriesColor={{ 'ta': '#e02f44', 'td': '#3274d9', 'tss': '#ca95e5' }}
                                    axisName="[°C]"
                                 />
                              </StationItem>
                           </div>
                        </Grid>
                     )}
                     {(arrayIsSet(stationData["rh"]) || arrayIsSet(stationData["igr"]) || arrayIsSet(stationData["ogr"])) && (
                        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                           <div ref={radChartDivRef} style={{ minHeight: '100%', minWidth: '100%' }}>
                              <StationItem elevation={0} sx={{ minHeight: '40vh', maxHeight: '40vh' }}>
                                 <StationComponentRadiationChart
                                    title={""}
                                    divRef={radChartDivRef}
                                    series={{ 'rh': stationData['rh'], 'igr': stationData['igr'], 'ogr': stationData['ogr'] }}
                                    seriesType={{ 'rh': 'line', 'igr': 'line', 'ogr': 'line' }}
                                    seriesAreaStyle={{ 'rh': false, 'igr': true, 'ogr': true }}
                                    seriesZIndex={{ 'rh': 2, 'igr': 0, 'ogr': 1 }}
                                    seriesColor={{ 'rh': '#d7b135', 'igr': '#bfd8ff', 'ogr': '#ffb87d' }}
                                    axisIndex={{ 'rh': 1, 'igr': 0, 'ogr': 0 }}
                                 />
                              </StationItem>
                           </div>
                        </Grid>
                     )}
                  </Grid>
               </div>
            </StationPaperElem >
         </div>
      </>);
}

export default StationPaper;
