import React, { useState, useContext } from 'react';
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import LocateControl from '../LocateControl';
import VectorTileLayer from './VectorTileLayer';
import ReportInfoBox from './ReportInfoBox';
import { tlurl, formatTimestampToUserLocale, truncateText } from '../utils';
import { decodeHTMLEntities, getNameForRegionId, formatName } from './utils';
import { MapContext, MapHandler } from '../MapContext';
import * as L from 'leaflet';

import 'leaflet.vectorgrid';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import './avalanchereport.css';

const AvalanchereportMap = (props) => {
   const [activeReport, setActiveReport] = useState({ "hidden": true });
   const { mapState } = useContext(MapContext);

   const showInfoBox = (region) => {
      if (!region) {
         setActiveReport({ "hidden": true });
         props.setSelectedBulletin(null);
         return;
      }
      const bulletin = props.data.getBulletinForRegion(region);
      const name = formatName(decodeHTMLEntities(getNameForRegionId(region)));
      const problems = props.data.getAvalancheProblems(region, props.amPmSelectorValue);
      if (!bulletin || !name || name == "Null") {
         setActiveReport({ "hidden": true });
         props.setSelectedBulletin(null);
         return;
      }
      const title = bulletin.avalancheActivity ? truncateText(decodeHTMLEntities(bulletin.avalancheActivity.highlights),10) : "";
      const res = {
         "date": formatTimestampToUserLocale(bulletin.publicationTime),
         "title": (title!="undefined")?title:"",
         "regionName": name,
         "avalanche": problems
      };
      setActiveReport(res);
      props.setSelectedBulletin(bulletin);
      return;
   };

   L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);


   return (
      <div className={"custom-map-div"}>
         <MapContainer
            center={mapState.center}
            zoom={mapState.zoom}
            style={{ width: '100%', height: '585px' }}
            attributionControl={false}
            className="custom-map"
            scrollWheelZoom={false}
            gestureHandling={true}
         >
            <TileLayer
               url={tlurl}
               attribution='CC by avalanche.report'
            />
            <AttributionControl position="bottomright" prefix={false} />
            <LocateControl />
            {props.data && <VectorTileLayer amPmSelectorValue={props.amPmSelectorValue} vectorGridInitializer={props.vectorGridInitializer} data={props.data} problems={props.problems} showInfoBox={showInfoBox} />}
            <ReportInfoBox report={activeReport} showInfoBox={showInfoBox} />
            <MapHandler />            
         </MapContainer>
      </div>
   );
};

export default AvalanchereportMap;
