import { createContext, useContext } from 'react';
import { useMapEvents } from 'react-leaflet';

export const MapContext = createContext({});

export const MapHandler = () => {
   const { setMapState } = useContext(MapContext);

   useMapEvents({
      moveend: (event) => {
         const map = event.target;
         setMapState({
            center: map.getCenter(),
            zoom: map.getZoom(),
         });
      }
   });

   return null;
};