import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { getParamName, getParamUnit, fixPointForParam, convertBigNumberToNumber } from '../utils';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { getKey, dec } from './decode';

import './alpinemeteo.css';


export default function StationInfoBox(props) {
   const visible = props.infoBoxVisible;
   if (!visible) {
      return <></>;
   }

   if (!props.stationData) {
      return (
         <div className="info-container">
            <IconButton style={{ "backgroundColor": "white", "left": "90%", "top": "inherit", "border": "2px solid" }} onClick={() => { props.closeBox() }}>
               <CloseIcon />
            </IconButton>
            <Card sx={{ minWidth: 250, maxWidth: 250 }}>
               <CardContent className="d-flex justify-content-center">
                     <CircularProgress color={"inherit"} />
               </CardContent>
            </Card>
         </div>
      );
   }

   const station = props.stationData;
   const stationMetainfo = station.metadata;
   const stationData = station.data;

   return (
      <div className="info-container">
         <IconButton style={{ "backgroundColor": "white", "left": "90%", "top": "inherit", "border": "2px solid" }} onClick={() => { props.closeBox() }}>
            <CloseIcon />
         </IconButton>
         <Card sx={{ minWidth: 250, maxWidth: 250 }}>
            <CardContent>
               <Typography gutterBottom align={"center"} sx={{ color: 'text.secondary', fontSize: 12 }}>
                  {stationMetainfo.lat}°N, {stationMetainfo.long}°E @ {stationMetainfo.alt}m
               </Typography>
               <Typography align={"center"} sx={{ fontSize: 16 }} >
                  <FaAngleDoubleRight style={{ marginTop: "-4px", marginRight: "3px" }} size={16} />
                  {stationMetainfo.name}
                  <FaAngleDoubleLeft style={{ marginTop: "-4px", marginLeft: "3px" }} size={16} />
               </Typography>
               {stationData && stationData.length > 0 ? (
                  <>
                     <Divider sx={{ opacity: 1, marginTop: "5px" }} />
                     {stationData.map((data, index) => (
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 1 }}>
                           <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 12 }}>
                              {getParamName(data.name)}: {convertBigNumberToNumber(dec(getKey(stationMetainfo.id), data.val)).toFixed(fixPointForParam(data.name))}{getParamUnit(data.name)}
                           </Typography>
                        </Box>
                     ))}
                  </>
               ) : (<></>)
               }
            </CardContent>
            <CardActions style={{ backgroundColor: "#ff5159" }} className="d-flex justify-content-center">
               <Button
                  style={{ color: "black", fontFamily: "Ubuntu", fontSize: 12 }}
                  size="small"
                  onClick={() => {
                     const element = document.getElementById("station-box");
                     if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                     }
                  }}>
                  Zur Station
               </Button>
            </CardActions>
         </Card>
      </div>
   );
}
