import colorConfig from './colorConfig'; // Import the shared color configuration

class ColorSelector {
  selectColor(type, value) {
    if (type == 'hs') {
      if (value < 25) return colorConfig.hs[0];
      else if (value < 50) return colorConfig.hs[1];
      else if (value < 75) return colorConfig.hs[2];
      else if (value < 100) return colorConfig.hs[3];
      else if (value < 150) return colorConfig.hs[4];
      else return colorConfig.hs[5];
    }

    if (type == 'hs24h') {
      if (value < 0) return colorConfig.hs24h[0];
      else if (value < 10) return colorConfig.hs24h[1];
      else if (value < 30) return colorConfig.hs24h[2];
      else if (value < 50) return colorConfig.hs24h[3];
      else if (value < 75) return colorConfig.hs24h[4];
      else if (value < 100) return colorConfig.hs24h[5];
      else return colorConfig.hs24h[6];
    }

    if (type == 'ta' || type === 'tss') {
      if (value < -30) return colorConfig.ta[0];
      else if (value < -20) return colorConfig.ta[1];
      else if (value < -10) return colorConfig.ta[2];
      else if (value < 0) return colorConfig.ta[3];
      else if (value < 10) return colorConfig.ta[4];
      else if (value < 20) return colorConfig.ta[5];
      else if (value < 30) return colorConfig.ta[6];
      else return colorConfig.ta[7];
    }

    if (type == 'vw' || type === 'vwmax') {
      if (value < 20) return colorConfig.vw[0];
      else if (value < 40) return colorConfig.vw[1];
      else if (value < 60) return colorConfig.vw[2];
      else if (value < 100) return colorConfig.vw[3];
      else return colorConfig.vw[4];
    }

    if (type == 'station') {
      if (value < 1000) return colorConfig.station[0];
      else if (value < 2000) return colorConfig.station[1];
      else if (value < 3000) return colorConfig.station[2];
      else return colorConfig.station[3];
    }

    console.error(`Type "${type}" not supported or value "${value}" not in range.`);
    return null;
  }
}

export default ColorSelector;
