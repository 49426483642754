import React from 'react';
import Grid from '@mui/material/Grid2';
import { avalancheIconLabels } from '../utils';
import AvalancheIcon from '../AvalancheIcon';
import MultiLevelRose from '../MultiLevelRose';

import './avalanchereport.css';

function AvalancheTypeIcon({ type }) {
   return (
      <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Ubuntu' }}>
         <AvalancheIcon avalancheType={type} style={{ height: "30px" }} />
         <span style={{ marginLeft: '10px', fontSize: '16px' }}>{avalancheIconLabels[type]}</span>
      </div>
   );
}

export function AvalancheConditionEntry(props) {
   return (
      <>
         {props.isFirstEntry ? null :
            <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '5px' }}>
               <hr style={{ border: '1px solid black', margin: '0 0 10px 10px' }} />
            </Grid>
         }

         <div>
            <Grid container >
               <Grid size={{ xs: 12, md: 5 }} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                  <AvalancheTypeIcon type={props.avalancheType} />
               </Grid>
               <Grid container size={{ xs: 12, md: 7 }} >
                  <MultiLevelRose conditionData={props.avalancheConditionData} cssIdentifier={props.cssIdentifier} width={"120px"} height={"100px"} />
               </Grid>
            </Grid>
         </div>
      </>
   );
}

export default AvalancheConditionEntry;