import React, { useState, useEffect } from 'react';
import ScrollToTop from "react-scroll-to-top";
import Navigation from '../Navigation';
import AlpinemeteoDateInfoBar from './AlpinemeteoDateInfoBar';
import AlpinemeteoMap from './AlpinemeteoMap';
import Station from './Station';
import Footer from '../Footer';
import dayjs from 'dayjs';

import { getStations, getDataForStation } from './query';
import { fixPointForParam, convertBigNumberToNumber } from '../utils';
import { getKey, dec } from './decode';

import '../common.css';
import StationIconBar from './StationIconBar';

function MainAlpinemeteo() {
  const [from, setFrom] = useState("-7d");
  const [to, setTo] = useState("0d");
  const [fromDate, setFromDate] = useState(dayjs(new Date()).subtract(6, 'day').toDate());
  const [toDate, setToDate] = useState(new Date());
  const [stations, setStations] = useState([]);
  const [stationFilter, setStationFilter] = useState("station");

  useEffect(() => {
    const allStationsQuery = async () => {
      const res = await getStations(stationFilter);
      if (res && res.length > 0) {
        setStations(res);
      }
    };
    allStationsQuery();
  }, [stationFilter]);

  const setNewDateRange = (dr) => {
    if (!dr || !dr[0] || !dr[1]) {
      setFrom("-7d");
      setTo("0d");
      return;
    }

    const currentDate = new Date();
    const givenDateObjectFrom = new Date(dr[0]);
    const givenDateObjectTo = new Date(dr[1]);
    //currentDate.setHours(0, 0, 0, 0);
    givenDateObjectFrom.setHours(0, 0, 0, 0);
    givenDateObjectTo.setHours(23, 59, 59, 999);

    // future?
    const fromIsInFuture = givenDateObjectFrom > currentDate;
    const toIsInFuture = givenDateObjectTo > currentDate;

    // Berechnet die Differenz in Tagen
    const differenceInTimeFrom = currentDate - givenDateObjectFrom;
    const differenceInMinutesFrom = Math.abs(differenceInTimeFrom / (1000 * 60));
    const differenceInTimeTo = currentDate - givenDateObjectTo;
    const differenceInMinutesTo = Math.abs(differenceInTimeTo / (1000 * 60));

    // Formatieren des Ergebnisses
    const formattedDifferenceFrom = `${!fromIsInFuture ? '-' : ''}${Math.round(differenceInMinutesFrom)}m`;
    const formattedDifferenceTo = `${!toIsInFuture ? '-' : ''}${Math.round(differenceInMinutesTo)}m`;

    setFrom(formattedDifferenceFrom);
    setTo(formattedDifferenceTo);
    setFromDate(givenDateObjectFrom);
    setToDate(givenDateObjectTo);
  }

  const [debouncedDate, setDebouncedDate] = useState([from, to]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStationData, setSelectedStationData] = useState(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDate([from, to]);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [from, to]);

  useEffect(() => {
    if (selectedStation) {
      // fetch data as specified in date range
      const dataForStationQuery = async () => {
        const sid = selectedStation["id"];
        const res = await getDataForStation(sid, from, to);
        const retarr = {};
        if (res && res["data"]) {
          const timestamps = res["data"].map(item => new Date(item.ts));
          const minTs = new Date(Math.min(...timestamps));
          const maxTs = new Date(Math.max(...timestamps));
          const uniqueParams = [...new Set(res["data"].map(item => item.name))];

          uniqueParams.map(param => {
            retarr[param] = [];
            retarr[param].push([minTs, null]);
            retarr[param].push([maxTs, null]);
          });

          const k = getKey(sid);
          res["data"].forEach(elem => {
            const param = elem["name"];
            const fixPoint = fixPointForParam(param);
            retarr[param].push([elem["ts"], convertBigNumberToNumber(dec(k, elem["val"])).toFixed(fixPoint)]);
          });
        }
        setSelectedStationData(retarr);
      };
      dataForStationQuery();
    } else {
      setSelectedStationData(null);
    }

  }, [debouncedDate, selectedStation]);

  return (
    <>
      <div>
        <Navigation />
        <AlpinemeteoDateInfoBar setNewDateRange={setNewDateRange} />
        <AlpinemeteoMap stations={stations} selectStation={setSelectedStation} stationFilter={stationFilter} />
        <StationIconBar setStationType={setStationFilter} />
        {(selectedStation && selectedStationData) &&
          <Station from={fromDate} to={toDate} stationMetadata={selectedStation} stationData={selectedStationData} />
        }
        <ScrollToTop smooth />
        <Footer />
      </div>
    </>
  );
}

export default MainAlpinemeteo;
