import React from 'react';
import { GiWindsock } from "react-icons/gi";
import { WiStrongWind } from "react-icons/wi";
import { FaTemperatureLow } from "react-icons/fa";
import { FaTemperatureArrowDown } from "react-icons/fa6";
import { AiOutlineColumnHeight } from "react-icons/ai";
import { MdTimeline } from "react-icons/md";


export const stationIcons = {
   vw: GiWindsock,
   vwmax: WiStrongWind,
   ta: FaTemperatureLow,
   tss: FaTemperatureArrowDown,
   hs: AiOutlineColumnHeight,
   hs24h: MdTimeline,
};

class StationIcon extends React.Component {
  getStationIcon(stationType) {
    const IconComponent = stationIcons[stationType];
    return IconComponent ? <IconComponent size={this.props.size} style={this.props.style} /> : <></>;
  }

  render() {
    const { stationType } = this.props;
    return this.getStationIcon(stationType);
  }
}

export default StationIcon;