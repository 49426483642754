import React from "react";
import { AttributionControl, MapContainer, TileLayer, useMap } from 'react-leaflet';
import BadgeMarker from './BadgeMarker';

function SetViewOnClick({ coords }) {
   const map = useMap();
   map.setView(coords, map.getZoom());

   return null;
}

export const StationComponentMap = (props) => {

   return (
      <MapContainer
         center={[props.lat, props.lng]}
         zoom={11}
         maxZoom={12}
         dragging={true}
         scrollWheelZoom={false}
         style={{ zIndex: 0, height: "100%", minHeight: "38vh", width: "100%" }}
         attributionControl={false}>
         <SetViewOnClick coords={[props.lat, props.lng]} />
         <TileLayer
            attribution='by skitourenguru.com'
            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            //url="https://map.skitourenguru.com/AP_SG_TOPO_MINI.tms?x={x}&y={y}&z={z}"
         />
         <BadgeMarker
            unit={""}
            color={"#121619"}
            position={[props.lat, props.lng]}
            content={props.name}
            shiftLeft={props.name.length * 4} />
         <AttributionControl position="bottomright" prefix={false} />
      </MapContainer>
   );
}