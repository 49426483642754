export const regionStyling = {
   "clickable": {
      "stroke": false,
      "fill": true,
      "fillColor": "black",
      "fillOpacity": 0.0
   },
   "mouseOut": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillColor": "#990000",
      "fillOpacity": .3      
   },
   "mouseOn": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillColor": "#990000",
      "fillOpacity": .7      
   },
   "hidden": {
      "stroke": false,
      "fill": false,
      "fillOpacity": 0.0
   },
   "marked": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillColor": "#990000",
      "fillOpacity": .7
   },   
};
