import { useMap } from 'react-leaflet';
import { useEffect } from "react";
import L from 'leaflet'

function Legend(props) {
  const className = props.className;

  const map = useMap();

  useEffect(() => {
    const legendComponent = L.Control.extend({
      options: {
        position: 'bottomleft',
      },

      onAdd: function() {
        const container = L.DomUtil.create('div', className);

        const grades = props.grades;
        const colors = props.colors;
        const labels = [];

        if(grades.length !== colors.length) {
          return container;
        }

        const labelstyle = "width: 18px; height: 18px; float: left; margin-left: 2px; margin-right: 3px; opacity: 1.0;";
        labels.push(`<i style="margin-left: 15px;">${className}</i>`);

        for (let i = 0; i < grades.length; i++) {
          const lab = grades[i];
          const color = colors[i];
          labels.push(`<i style="${labelstyle}background-color:${color}"></i> ${lab}`);
        }

        container.innerHTML = labels.join('<br>');
        container.style.opacity = 0.8;
        container.style.background = "rgb(34, 37, 43)";
        container.style.borderRadius = "2px";
        container.style.padding = "5px";
        container.style.color = "white";

        return container;
      },
    });

    const legendComponentInstance = new legendComponent();
    map.addControl(legendComponentInstance);

    return () => {
      map.removeControl(legendComponentInstance);
    };
  }, [className, map, props]);

  return null;
}

export default Legend;