
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getParamName, getParamUnit } from '../utils';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

export const StationComponentLatestData = (props) => {
   const { t } = useTranslation();

   let maxTs = "";
   const pv = props.vals;
   if(pv) {
      const pve = Object.entries(pv);
      for(let i=0;i<pve.length;++i) {
         const dataArr = pve[i][1];
         if(dataArr.length&&(dataArr[dataArr.length - 1][0] > maxTs)) {
            maxTs = dataArr[dataArr.length - 1][0];
         }
      }
      const localeFormat = dayjs.localeData().longDateFormat('LLLL'); 
      const maxTsDate = new Date(maxTs);
      maxTs = dayjs(maxTsDate).format(localeFormat);
   }

   return (
      <TableContainer component={Paper}>
         <Table aria-label="simple table">
            <caption>{"Letzte Messwerte"}: {maxTs}</caption>
            <TableHead>
               <TableRow>
                  <TableCell><b>{"Parameter"}</b></TableCell>
                  <TableCell align="right"></TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {Object.entries(props.vals).map(([key, values]) => (
                  (values.length > 0) && (
                     <TableRow key={key}>
                        <TableCell component="th" scope="row">{getParamName(key,t)}</TableCell>
                        <TableCell align="right">{values[values.length - 1][1] + " " + getParamUnit(key)}</TableCell>
                     </TableRow>)
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
}