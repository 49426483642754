import React from 'react';

class AvalancheIcon extends React.Component {
   getAvalancheIcon(avalancheType) {
      const path = "icons/"+avalancheType+".png";
      return <img src={path} alt="" style={this.props.style} />;
   }

   render() {
      const { avalancheType } = this.props;
      return this.getAvalancheIcon(avalancheType);
   }
}

export default AvalancheIcon;