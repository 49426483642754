import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';

const customIcons = (fontSize) => ({
   1: {
      icon: <InfoIcon color="success" style={{ fontSize }} />,
      label: 'Einfache Lawinensituation',
   },
   2: {
      icon: <WarningIcon color="warning" style={{ fontSize }} />,
      label: 'Schwierige Lawinensituation',
   },
   3: {
      icon: <DangerousIcon color="error" style={{ fontSize }} />,
      label: 'Heimtückische Lawinensituation',
   },
});

function IconContainer(props) {
   const { value, fontSize, ...other } = props;
   return <span {...other}>{customIcons(fontSize)[value].icon}</span>;
}

IconContainer.propTypes = {
   value: PropTypes.number.isRequired,
   fontSize: PropTypes.string, // Optional Prop for fontSize
};

export default function GOMRating(props) {
   return (
      <div
         style={{
            display: 'flex',
            alignItems: 'center',
         }}
      >
         <IconContainer value={props.value} fontSize={props.iconFontSize} />
         <span
            style={{
               marginLeft: '0.2rem',
               fontSize: props.fontSize,
               fontWeight: 'bold',
               fontFamily: 'Ubuntu',
            }}
         >
            {customIcons(props.iconFontSize)[props.value].label}
         </span>
      </div>
   );
}

GOMRating.propTypes = {
   value: PropTypes.number.isRequired,
   fontSize: PropTypes.string, // Optional Prop for fontSize
   iconFontSize: PropTypes.string, // Optional Prop for fontSize
};

GOMRating.defaultProps = {
   iconFontSize: '2.0rem', 
   fontSize: '16px'
};
