import React from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap";
import Grid from '@mui/material/Grid2';

import { stationIconLabels } from "../utils";
import StationIcon from "./StationIcon";

const StationIconBar = (props) => {
   const [selection, setSelection] = React.useState('station');
   const handleSelection = (newSelection) => {
      if((newSelection==selection)||!newSelection) {
         setSelection("station");
         props.setStationType("station");
      } else {
         setSelection(newSelection);
         props.setStationType(newSelection);
      }
   };

   return (
      <div style={{ maxWidth: "1100px", marginLeft: "auto", marginRight: "auto" }} >
         <Grid container>
            <Grid size={{ xs: 12 }} className="d-flex justify-content-center" >
               <div style={{ margin: '10px' }} >
                  <Container>
                     <span style={{ fontSize: "14px" }}>Filter nach Stationstyp </span>
                     <Row className="justify-content-center">
                        <Col xs={12}>
                           <Row>
                              {Object.entries(stationIconLabels).map((elem) => (
                                 <Col xs={6} sm={4} md={2} className="mb-2" style={{ border: (selection == elem[0]) ? "1px solid black" : "1px solid white" }}>
                                    <Tooltip title={elem[1]}>
                                       <Button
                                          onClick={() => handleSelection(elem[0])}
                                          color="black"
                                          fullWidth >
                                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                             <StationIcon stationType={elem[0]} size={40} />
                                             <span style={{ fontSize: "10px" }}>{elem[1]}</span>
                                          </div>
                                       </Button>
                                    </Tooltip>
                                 </Col>
                              ))}
                           </Row>
                        </Col>
                     </Row>
                  </Container>
               </div>
            </Grid>
         </Grid>
      </div>
   );
};

export default StationIconBar;
