import React, { useState, useEffect } from "react";
import { EChart } from '@kbox-labs/react-echarts'
import { getParamName, getParamUnit } from '../utils';
import { useTranslation } from "react-i18next";

export const StationComponentRadiationChart = (props) => {
   const divRef = props.divRef;
   const [width, setWidth] = useState(0);
   const [height, setHeight] = useState(0);

   const { t } = useTranslation();

   useEffect(() => {
      const handleResize = (entries) => {
         for (let entry of entries) {
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
         }
      };

      const observer = new ResizeObserver(handleResize);
      const observed = divRef.current;

      if (divRef.current) {
         observer.observe(divRef.current);
      }

      return () => {
         if (observed) {
            observer.unobserve(observed);
         }
      };
   }, [divRef]);

   if (!props.series) {
      // no data given
      return <></>;
   }

   const seriesArr = [];
   const seriesColor = [];
   const seriesLegend = [];

   Object.entries(props.series).forEach(([key, values]) => {
      if(values===[]) {
         return;
      }
      const paramName = getParamName(key,t)+" ["+getParamUnit(key)+"]";

      seriesArr.push({
         type: props.seriesType[key],
         showSymbol: false,
         data: values,
         name: paramName,
         areaStyle: {
            opacity: props.seriesAreaStyle&&props.seriesAreaStyle[key]?1.0:0.0
         },
         zlevel: (props.seriesZIndex&&props.seriesZIndex[key])?props.seriesZIndex[key]:0,
         yAxisIndex: (props.axisIndex&&props.axisIndex[key])?props.axisIndex[key]:0,
      });
      seriesColor.push(props.seriesColor[key]);
      seriesLegend.push(paramName);
   });

   return (
      <>
         {props.title}<p/>
         {(!!width) && (
            <EChart
               key={width}
               renderer={'svg'}
               width={width - 15}
               height={height}
               animation={false}
               xAxis={{
                  type: 'time',
                  show: true,
                  boundaryGap:false,
                  splitLine: { show: true }
               }}
               tooltip={{ trigger: 'axis' }}
               grid={{
                  show: true,
                  top: "35px",
                  left: "50px",
                  right: "40px",
                  bottom: "120px"
               }}
               legend={{ 
                  data: seriesLegend,
                  bottom : 50 
               }}
               yAxis={[
                  {
                     name: '[W/m²]',
                     type: 'value',
                     min: 0,
                     max: 1000,
                     boundaryGap: [0, '30%'],
                     axisTick: { show: true },
                     minorTick: { show: true },
                     minorSplitLine: { show: true }
                  },
                  {
                     id: 1,
                     name: '[%]',
                     nameLocation: 'end',
                     show: true,
                     min: 0,
                     max: 100,
                     axisTick: { show: false },
                     minorTick: { show: false },
                     minorSplitLine: { show: false },
                     splitLine: { show: false },
                     type: 'value'                   
                  }
               ]}
               series={seriesArr}
               color={seriesColor}
            />)}
      </>
   );
}