import React, { useContext } from 'react';
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import LocateControl from '../LocateControl';
import VectorTileLayer from './VectorTileLayer';
import ReportInfoBox from './ReportInfoBox';
import { tlurl, formatTimestampToUserLocale } from '../utils';
import { MapContext, MapHandler } from '../MapContext';
import * as L from 'leaflet';

import 'leaflet.vectorgrid';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import './snowreport.css';

const SnowreportMap = (props) => {
   const { mapState } = useContext(MapContext);

   const showInfoBox = (region) => {
      if (!region) {
         props.setActiveReport(null);
         return;
      }
      if (!region.latestReport) {
         const today = new Date();
         const localDate = formatTimestampToUserLocale(today.toLocaleDateString());
         props.setActiveReport({
            "date": localDate,
            "title": "Kein aktueller Schneebericht",
            "regionName": region.name,
            "anchor": null,
         });
         return;
      }
      for (const repId in props.reports) {
         const rep = props.reports[repId];
         if (rep.documentId === region.latestReport) {
            props.setActiveReport({
               "date": formatTimestampToUserLocale(rep.valid_from),
               "title": rep.title,
               "regionName": region.name,
               "anchor": rep.documentId,
               "snow": rep.snow,
               "avalanche": rep.avalanche,
               "grade_of_manageability": rep.grade_of_manageability
            });
            return;
         }
      }
   };

   L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
   
   return (
      <div className={"custom-map-div"}>
         <MapContainer
            center={mapState.center}
            zoom={mapState.zoom}
            style={{ width: '100%', height: '585px' }}
            attributionControl={false}
            className="custom-map"
            scrollWheelZoom={false}
            gestureHandling={true}
         >
            <TileLayer
               url={tlurl}
               attribution='CC by avalanche.report'
            />
            <AttributionControl position="bottomright" prefix={false} />
            <LocateControl />
            <VectorTileLayer activeReport={props.activeReport} vectorGridInitializer={props.vectorGridInitializer} regions={props.regions} showInfoBox={showInfoBox} />
            <ReportInfoBox report={props.activeReport} showInfoBox={showInfoBox} />
            <MapHandler />
         </MapContainer>
      </div>
   );
};

export default SnowreportMap;
