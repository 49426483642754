import React from 'react';
import { useMap } from 'react-leaflet';
import { pbfUrl } from '../utils';
import { regionStyling } from './regionStyles';
import * as L from 'leaflet';

L.DomEvent.fakeStop = function () {
   return true;
}

function VectorTileLayer(props) {
   const map = useMap();
   const regions = props.regions;

   React.useEffect(() => {
      let featureSet = new Set();
      const vectorTileOptions = {
         pane: "markerPane",
         rendererFactory: L.svg.tile,
         maxZoom: 10,
         interactive: true,
         vectorTileLayerStyles: {
            'micro-regions': function (properties, zoom) {
               return regionStyling.clickable;
            },
            'micro-regions_elevation': function (properties, zoom) {
               return regionStyling.hidden;
            },
            'outline': function (properties, zoom) {
               return regionStyling.hidden;
            },
         },
         getFeatureId: function (feature) {
            if (feature && feature.properties.elevation !== undefined) {
               return feature.properties.id + "_" + feature.properties.elevation;
            }
            const rid = feature.properties.id;
            featureSet.add(rid);
            return rid;
         },
      };

      const vectorGrid = L.vectorGrid.protobuf(
         pbfUrl,
         vectorTileOptions
      ).addTo(map);

      // Event-Handler hinzufügen
      props.vectorGridInitializer(vectorGrid,featureSet,props.showInfoBox);

      return () => {
         map.removeLayer(vectorGrid);
      };

   }, [map, regions]);

   return null;
}

export default VectorTileLayer;