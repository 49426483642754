import React, { useState, useEffect, useRef } from 'react';
import ScrollToTop from "react-scroll-to-top";
import dayjs from 'dayjs';
import Navigation from '../Navigation';
import AvalancheDateInfoBar from '../Avalanchereport/AvalanchereportDateInfoBar';
import { getAvalancheData } from './query';
import AvalanchereportMap from './AvalanchereportMap';
import { getStyleForAvalancheRegion, getRegRatingForProperty } from './utils';
import { regionStyling } from './regionStyles';
import AvalancheBulletinHelper from './AvalancheBulletinHelper';
import AmPmSelector from './AmPmSelector';
import Report from './Report';
import Footer from '../Footer';

import './avalanchereport.css';

const getInitialDate = () => {
  const now = dayjs().tz("Europe/Berlin"); // CET/CEST je nach Jahreszeit
  if (now.hour() >= 17) {
    return now.add(1, 'day').startOf('day'); // Morgen, falls nach 17 Uhr
  }
  return now.startOf('day'); // Heute
};

function MainAvalanchereport() {
  const initialDate = getInitialDate();
  const [avalancheData, setAvalancheData] = useState(null);
  const [avalancheRatings, setAvalancheRatings] = useState([]);
  const [avalancheProblems, setAvalancheProblems] = useState([]);
  const [hasAmPm, setHasAmPm] = useState(false);
  const [amPmSelectorValue, setAmPmSelectorValue] = useState("earlier");
  const [date, setDate] = useState(initialDate);
  const [debouncedDate, setDebouncedDate] = useState(date);
  const [selectedBulletin, setSelectedBulletin] = useState(null);
  const [mapResetter, setMapResetter] = useState(() => () => { });
  const selectedBulletinRef = useRef(selectedBulletin);

  useEffect(() => {
    selectedBulletinRef.current = selectedBulletin;
    if(!selectedBulletin) {
      mapResetter();
    }
  }, [selectedBulletin]);

  const vectorGridInitializer = (vectorGrid, featureSet, elevatedFeatureSet, showInfoBox) => {

    const setStyleForAllElevations = (regId, style) => {
      const maxRatings = avalancheData ? avalancheData.getMaxDangerRatingsForRegionsWithElevation() : {};
      if (!maxRatings[regId]) {
        return;
      }
      const maxRatingsForRegion = maxRatings[regId];
      const lowStyle = getStyleForAvalancheRegion(maxRatingsForRegion[amPmSelectorValue + "_low"], style)
      const highStyle = getStyleForAvalancheRegion(maxRatingsForRegion[amPmSelectorValue + "_high"], style)
      const lowHighStyle = getStyleForAvalancheRegion(maxRatingsForRegion[amPmSelectorValue + "_low_high"], style)
      vectorGrid.setFeatureStyle(regId + "_low", lowStyle);
      vectorGrid.setFeatureStyle(regId + "_high", highStyle);
      vectorGrid.setFeatureStyle(regId + "_low_high", lowHighStyle);
    };

    vectorGrid.on('mouseover', function (e) {
      // reset the styles to mouseOut
      if (!selectedBulletinRef.current) {
        elevatedFeatureSet.forEach((val) => {
          setStyleForAllElevations(val, regionStyling.mouseOut);
        });
        featureSet.forEach((val) => {
          vectorGrid.setFeatureStyle(val, regionStyling.clickable);
        });
        const regId = e.sourceTarget.properties.id;
        if (elevatedFeatureSet.has(regId)) {
          setStyleForAllElevations(regId, regionStyling.mouseOn);
          featureSet.forEach((val) => {
            if (regId.startsWith(val)) {
              vectorGrid.setFeatureStyle(val, regionStyling.clickableOn);
            }
          });
        }
      }
    });

    vectorGrid.on('mouseout', function (e) {
      // reset the styles to mouseOut  
      if (!selectedBulletinRef.current) {
        elevatedFeatureSet.forEach((val) => {
          setStyleForAllElevations(val, regionStyling.mouseOut);
        });
        featureSet.forEach((val) => {
          vectorGrid.setFeatureStyle(val, regionStyling.clickable);
        });
        const regId = e.sourceTarget.properties.id;
        if (elevatedFeatureSet.has(regId)) {
          setStyleForAllElevations(regId, regionStyling.mouseOut);
          featureSet.forEach((val) => {
            if (regId.startsWith(val)) {
              vectorGrid.setFeatureStyle(val, regionStyling.clickableOn);
            }
          });
        }
      }
    });


    vectorGrid.on('click', function (e) {
      const reg = e.sourceTarget.properties.id;
      // reset the styles to mouseOut      
      elevatedFeatureSet.forEach((val) => {
        setStyleForAllElevations(val, regionStyling.mouseOut);
      });
      featureSet.forEach((val) => {
        vectorGrid.setFeatureStyle(val, regionStyling.clickable);
      });
      console.log("click on ");
      console.log(e.sourceTarget.properties);
      const regions = avalancheData.extendRegions(avalancheData.getRegionsFromSameBulletin(reg));
      for (const rid in regions) {
        const elem = regions[rid];
        setStyleForAllElevations(elem.regionID, regionStyling.mouseOn);
        featureSet.forEach((val) => {
          if (elem.regionID.startsWith(val)) {
            vectorGrid.setFeatureStyle(val, regionStyling.clickableOn);
          }
        });
      }
      // do the click handling
      if (reg) {
        showInfoBox(reg);
      } else {
        showInfoBox(null);
      }
    });

    const mapResetLambda = () => () => {
      elevatedFeatureSet.forEach((val) => {
        setStyleForAllElevations(val, regionStyling.mouseOut);
      });
      featureSet.forEach((val) => {
        vectorGrid.setFeatureStyle(val, regionStyling.clickable);
      });
    }
    setMapResetter(mapResetLambda);

    vectorGrid.on('load', function (e) {
      setHasAmPm(avalancheData.hasAmPmDifference());
    })
  };


  const increaseDate = () => {
    const newDate = date.add(1, 'day');
    if (!newDate.isAfter(initialDate)) {
      setDate(newDate);
    }
  };

  const decreaseDate = () => {
    setDate(date.subtract(1, 'day'));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDate(date);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [date]);

  useEffect(() => {
    getAvalancheData(debouncedDate)
      .then((data) => {
        const avaData = new AvalancheBulletinHelper(data);
        setAvalancheData(avaData);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Reports.', error);
      });
  }, [debouncedDate]);

  return (
    <>
      <div>
        <Navigation />
        <AvalancheDateInfoBar
          decreaseDate={decreaseDate}
          increaseDate={increaseDate}
          date={date}
          setDate={setDate} />
        <AvalanchereportMap
          vectorGridInitializer={vectorGridInitializer}
          ratings={avalancheRatings}
          problems={avalancheProblems}
          data={avalancheData}
          amPmSelectorValue={amPmSelectorValue}
          setSelectedBulletin={setSelectedBulletin}
        />
        {hasAmPm && <AmPmSelector hasAmPm={hasAmPm} setAmPm={setAmPmSelectorValue} amPm={amPmSelectorValue} />}
        {selectedBulletin && <Report selectedBulletin={selectedBulletin} />}
        <ScrollToTop smooth />
        <Footer />
      </div>
    </>
  );
}

export default MainAvalanchereport;
