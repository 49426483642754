import React, { useState } from "react";
import { CircleMarker, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet'
import './alpinemeteo.css';

const BadgeMarker = (props) => {
   const map = useMapEvents({
      zoomend: () => {
         // Aktualisieren Sie die Komponente, wenn sich die Zoomstufe ändert
         setZoom(map.getZoom());
      },
   });

   const [zoom, setZoom] = useState(map.getZoom());

   if (props.content === undefined) {
      return <></>;
   }

   const showBadge = zoom >= 11;
   const content = props.content + props.unit;
   const shiftLeft = props.shiftLeft ? props.shiftLeft : 15; 
   const shiftTop = props.shiftTop ? props.shiftTop : 15; 
   const col = props.color;
   if (content.length) {
      return (
         <>
            <CircleMarker
               eventHandlers={props.eventHandlers}
               center={props.position}
               radius={zoom - 3}
               color={col}>
            </CircleMarker>
            <Marker
               position={props.position}
               eventHandlers={props.eventHandlers}
               icon={L.divIcon({
                  className: '',
                  html: showBadge ? (
                     '<i class="markerContext">' + content + '</i>'
                  ) : (
                     ""
                  ),
                  iconAnchor: [shiftLeft + content.length, -shiftTop],
                  iconSize: [content.length*10,30], 
               })}>
            </Marker>
         </>);
   }
   return (
   <>
   <CircleMarker
      eventHandlers={props.eventHandlers}
      center={props.position}
      radius={zoom - 3}
      color={props.color}>
   </CircleMarker>
   </>
   );
};

export default BadgeMarker;