export const regionStyling = {
   "clickable": {
      "stroke": false,
      "fill": true,
      "fillColor": "black",
      "fillOpacity": 0.0
   },
   "clickableOn": {
      "color": "black",
      "stroke": true,
      "fill": true,
      "fillColor": "black",
      "fillOpacity": 0.0
   },   
   "mouseOut": {
      "fill": true,
      "stroke": false,
      "fillOpacity": .5
   },
   "mouseOn": {
      "fill": true,
      "stroke": false,
      "fillOpacity": .85      
   },
   "hidden": {
      "stroke": false,
      "fill": false,
      "fillOpacity": 0.0
   },
   "marked": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillOpacity": .8
   },   
};
