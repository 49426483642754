export function formatTimestampToUserLocale(timestamp, withTime = false) {
   const date = new Date(timestamp);
   let options = {
      // Optional: 'timeZone': 'Europe/Berlin',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
   };
   if (withTime) {
      options = {
         // Optional: 'timeZone': 'Europe/Berlin',
         weekday: 'long',
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
      };
   }
   return date.toLocaleString(undefined, options);
}

function isWebpSupported() {
   // check webp support.
   var elem = document.createElement('canvas');

   if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
   }
   else {
      // very old browser like IE 8, canvas not supported
      return false;
   }
}

const extension = isWebpSupported() ? ".webp" : ".png";
export const tlurl = "https://static.avalanche.report/tms/{z}/{x}/{y}" + extension;

export const pbfUrl = "https://static.avalanche.report/eaws_pbf/{z}/{x}/{y}.pbf";

export function getRegionById(regions, regionId) {
   for (let region of regions) {
      let regex = new RegExp(region.regex);
      if (regex.test(regionId)) {
         return region;
      }
   }
   return null;
}

export function isCurrentFeature(properties, today = new Date().toISOString().substring(0, 10)) {
   return (
      (!properties.start_date || properties.start_date <= today) &&
      (!properties.end_date || properties.end_date > today)
   );
}

export function setStyleForFeature(regions, vectorGrid, sourceFeature, featureSet, styleBase) {
   // hole region mit gegebenem regionscode
   const region = getRegionById(regions, sourceFeature);
   if (region) {
      // markiere alle features für die derselbe regions regex matched
      // und setze den feature style.
      const regex = new RegExp(region.regex);
      featureSet.forEach(function (targetId, index) {
         if (regex.test(targetId)) {
            vectorGrid.setFeatureStyle(targetId.substring(0, 8), styleBase);
         }
      })
   }
}

export function setStyleForSnowCondition(reports, vectorGrid, snowCond, featureSet, styleBase) {
   for (const rid in reports) {
      const report = reports[rid];
      if (report) {
         let hasSnowCondition = false;
         for (const sid in report.snow) {
            hasSnowCondition |= report.snow[sid].snow_type === snowCond;
         }
         if (!hasSnowCondition) {
            // skip region
            continue;
         }
         for (const regId in report.regions) {
            const region = report.regions[regId];
            const regex = new RegExp(region.regex);
            featureSet.forEach(function (targetId, index) {
               if (regex.test(targetId)) {
                  vectorGrid.setFeatureStyle(targetId.substring(0, 8), styleBase);
               }
            })
         }
      }
   }
}

export function setStyleForAvalancheCondition(reports, vectorGrid, avalancheCond, featureSet, styleBase) {
   for (const rid in reports) {
      const report = reports[rid];
      if (report) {
         let hasAvaCondition = false;
         for (const sid in report.avalanche) {
            hasAvaCondition |= report.avalanche[sid].problem === avalancheCond;
         }
         if (!hasAvaCondition) {
            // skip region
            continue;
         }
         for (const regId in report.regions) {
            const region = report.regions[regId];
            const regex = new RegExp(region.regex);
            featureSet.forEach(function (targetId, index) {
               if (regex.test(targetId)) {
                  vectorGrid.setFeatureStyle(targetId.substring(0, 8), styleBase);
               }
            })
         }
      }
   }
}

export const getExpoList = (expos) => {
   if (!expos || !Array.isArray(expos)) {
      return [];
   }
   return expos
      .filter((expoEntry) => expoEntry && expoEntry.expo != null)
      .map(expoEntry => expoEntry.expo);
};

export const snowIconLabels = {
   pulverschnee: 'Pulverschnee',
   firn: 'Firn',
   bruchharsch: 'Bruchharsch',
   faulschnee: 'Faulschnee',
   vereist: 'Vereiste Oberfläche',
   harschdeckel: 'Harschdeckel',
   sommerschnee: 'Sommerschnee',
   noppenpulver: 'Noppenpulver',
   windbeeinflusst: 'Windbeeinflusster Schnee'
};

export const avalancheIconLabels = {
   newsnow: 'Neuschnee',
   windslab: 'Triebschnee',
   weaklayer: 'Altschnee',
   wetsnow: 'Nassschnee',
   glidesnow: 'Gleitschnee',
}

export const stationIconLabels = {
   vw: 'Wind',
   vwmax: 'Böe',
   ta: 'Lufttemp.',
   tss: 'Oberflächentemp.',
   hs: 'Schneehöhe',
   hs24h: '24h Schnee'
}

export const getParamName = (param) => {
   switch (param) {
      case "hs": return "Schneehöhe";
      case "hs24h": return "24h Schneedifferenz";
      case "ta": return "Lufttemperatur";
      case "td": return "Taupunkt";
      case "tss": return "Oberflächentemperatur";
      case "rh": return "Luftfeuchtigkeit";
      case "igr": return "Strahlung (ein)";
      case "ogr": return "Strahlung (aus)";
      case "vw": return "Windgeschwindigkeit";
      case "vwmax": return "Böe";
      case "dw": return "Windrichtung";
   }
   return "";
}

export const getParamUnit = (param) => {
   switch (param) {
      case "hs": return "cm";
      case "hs24h": return "cm";
      case "ta": return "°C";
      case "td": return "°C";
      case "tss": return "°C";
      case "rh": return "%";
      case "igr": return "W/m²";
      case "ogr": return "W/m²";
      case "vw": return "km/h";
      case "vwmax": return "km/h";
      case "dw": return "°";
   }
   return "";
}

export function convertBigNumberToNumber(value) {
   if (value["value"]) {
      return Number(value.toString());
   }
   return Number(value);
}

export function arrayIsSet(arr) {
   if (!arr) { return false; }
   if (arr == []) { return false; }
   if (!arr.length) { return false; }
   return true;
}

export function fixPointForParam(param) {
   if (param == "hs" ||
      param == "hs24h" ||
      param == "ta" ||
      param == "td" ||
      param == "tss") {
      return 1;
   }
   return 0;
}

export function mergeExpos(existingExpos, newExpos) {
   const existingSet = new Set(existingExpos);
   const newSet = new Set(newExpos);
   return Array.from(new Set([...existingSet, ...newSet]));
}

export const getGradeOfManageability = (grade) => {
   if (!grade) { return 0; }
   if (grade == "leicht") { return 1; }
   if (grade == "schwierig") { return 2; }
   if (grade == "kompliziert") { return 3; }
   return 0;
};

export function truncateText(text, maxWords) {
   const words = text.split(' ');
   if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
   }
   return text; // Wenn der Text kürzer ist, den ursprünglichen Text zurückgeben
}

export function truncateString(str, maxLength) {
   if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
   }
   return str;
}