// Shared color configuration
const colorConfig = {
   ta: ["#172b2b", "#264b59", "#2b7397", "#6aa216", "#716f28", "#b15c1b", "#d73c3c", "#511543"],
   tss: ["#172b2b", "#264b59", "#2b7397", "#6aa216", "#716f28", "#b15c1b", "#d73c3c", "#511543"],
   hs: ["#5DAD4A", "#54D299", "#7cb8cf", "#3A778D", "#1E1E6A", "#581527"],
   hs24h: ["#e0de38", "#5DAD4A", "#54D299", "#7cb8cf", "#3A778D", "#1E1E6A", "#581527"],
   vw: ["#003b5e", "#39B133", "#E3B642", "#E13117", "#A30101"],
   vwmax: ["#003b5e", "#39B133", "#E3B642", "#E13117", "#A30101"],
   station: ["#6a65cc", "#4aa44e", "#f89921", "#346bb8"]
 };
 
 export default colorConfig;
 