let avalancheCache = {};

export const getAvalancheData = async (date) => {
   const formattedDate = date.format('YYYYMMDD');

   // Überprüfen, ob der Typ bereits im Cache vorhanden ist und die Daten aktuell sind
   if (avalancheCache[formattedDate]) {
      return avalancheCache[formattedDate];
   }

   if (Object.keys(avalancheCache).length > 20) {
      avalancheCache = {};
   }

   const urls = [ `https://translate.cr.baeren-lechtal.com/${formattedDate}/DE` ];

   try {
      const fetchPromises = urls.map(url =>
         fetch(url)
            .then(response => {
               if (response.ok) {
                  return response.json();
               }
               if (response.status === 404) {
                  return null; // Ignoriere 404-Fehler
               }
               throw new Error(`HTTP Error: ${response.status}`);
            })
            .catch(() => null) // Ignoriere andere Fetch-Fehler
      );

      const results = await Promise.all(fetchPromises);

      // Sammle alle gültigen Bulletins
      let allBulletins = [];
      results.forEach(data => {
         if (data && data.bulletins) {
            allBulletins = allBulletins.concat(data.bulletins);
         }
      });

      if (allBulletins.length === 0) {
         return { "error": "No valid data found, all requests failed." };
      }

      // Cache aktualisieren
      avalancheCache[formattedDate] = allBulletins;
      return allBulletins;
   } catch (error) {
      console.error('Error querying data: ', error);
      return { "error": error.toString() };
   }
};
