import React from 'react';
import Legend from './Legend'; 
import colorConfig from './colorConfig'; 

class LegendSelector extends React.Component {
  render() {
    const { type } = this.props;

    switch (type) {
      case 'station':
        return (
          <Legend 
            className={"Höhenlage"} 
            grades={["<1000m", "1000m-2000m", "2000m-3000m", ">3000m"]} 
            colors={colorConfig[type]} 
          />
        );
      
      case 'vw':
      case 'vwmax':
        return (
          <Legend 
            className={"Windgeschwindigkeit"} 
            grades={["<20km/h", "20km/h-40km/h", "40km/h-60km/h", "60km/h-100km/h", ">100km/h"]} 
            colors={colorConfig[type]} 
          />
        );
      
      case 'hs':
        return (
          <Legend 
            className={"Schneehöhe"} 
            grades={["<25cm", "25cm-50cm", "50cm-75cm", "75cm-100cm", "100cm-150cm", ">150cm"]} 
            colors={colorConfig[type]} 
          />
        );
      
      case 'hs24h':
        return (
          <Legend 
            className={"Schneedifferenz"} 
            grades={["<0cm", "0cm-10cm", "10cm-30cm", "30cm-50cm", "50cm-75cm", "75-100cm", ">100cm"]} 
            colors={colorConfig[type]} 
          />
        );

      case 'ta':
      case 'tss':
        return (
          <Legend 
            className={"Temperatur"} 
            grades={["<-30°C", "-30°C – -20°C", "-20°C – -10°C", "-10°C – 0°C", "0°C – 10°C", "10°C – 20°C", "20°C – 30°C", ">30°C"]} 
            colors={colorConfig[type]} 
          />
        );

      default:
        return null; // Optionally, return a default or null if the type is not matched
    }
  }
}

export default LegendSelector;
